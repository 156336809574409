import * as React from 'react'
import NavHeader from '../../../components/navHeaderEn'
import SiteFooter from '../../../components/footerEn'
import PageTitle from '../../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <Link to="/en/eGovernment"></Link>
                            <h1>Unified payments system</h1>
                           
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                        <h2>Scope</h2>
                        <p>Traditionally, the services of the state in Slovakia were paid for by paper form stamps at post office, in overall annual volume roughly 200 mil. Euro. It was impossible to determine an exact number.</p>
                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution</h2>
                        <p>This system was unstainable and within a reform of the public administration it was replaced with the central information system, which provided a complex payment service for citizens and all institutions of the public administration.</p>
                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution benefits</h2>
                        <h3>Citizens:</h3>
                        <ul>
                            <li>Comfortable way of a payment for the public administration services via all payment channels and devices (mobile, cash register, kiosk, bank transfer, cash, card) 
                            </li>
                            <li>Immediate overview about all state payments</li>
                        </ul>
                        <h3>Ministry of Finance:</h3>
                        <ul>
                            <li>Immediate overview about all payments for individual services, into individual institutions and individual citizens and entrepreneurs</li>
                            <li>Elimination of majority of frauds</li>
                            <li>Immediate overview about a demand after the public administration services as well as after performances of individual bureaus in respect to payments for services</li>
                        </ul>
                        <h3>Public administration:</h3>
                        <ul>
                            <li>Automatization of processes regarding issuing a payment order and also the inspection of payment for the service</li>
                            <li>Comfortable and reliable way of issuing a payment order</li>
                            <li>Comfortable and reliable way of obtaining information about a payment for the service</li>
                        </ul>
                    </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment